
import Page from '@/pages/_slug/index.vue'
import pageQuery from '@/apollo/queries/page'
export default {
  components: {
    Page,
  },
  async asyncData({ error, app, params }) {
    const slug = params.slug ? params.slug : 'home'
    const page = await app.apolloProvider.defaultClient
      .query({
        query: pageQuery,
        variables: { slug },
      })
      .then(({ data }) => data?.page?.data)
    if (!page) {
      return error({ statusCode: 404, message: 'Not found' })
    }
    return { page }
  },
}
