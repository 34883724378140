export default {
  head() {
    return {
      title: this.metaTitle,
      link: this.metaLinks,
      meta: this.metaData,
    }
  },
  computed: {
    // head meta
    metaTitle() {
      if (this?.page?.attributes) {
        return this?.page?.attributes?.meta_data?.title ? this.page.attributes.meta_data.title : this.page.attributes.title
      }
      return null
    },
    metaUrl() {
      return `${this.$config.BASE_URL}${this.$route.path !== '/' ? this.$route.path : ''}`
    },
    metaLinks() {
      return [
        { href: this.metaUrl, rel: 'canonical' },
        { href: this.metaUrl, rel: 'alternate', hreflang: 'en-gb' },
      ]
    },
    metaData() {
      const metaData = [{ name: 'og:url', content: this.metaUrl }]
      if (this?.page?.attributes?.meta_data?.title) {
        metaData.push({ hid: 'og:title', property: 'og:title', content: this.page.attributes.meta_data.title }, { hid: 'twitter:title', name: 'twitter:title', content: this.page.attributes.meta_data.title })
      }
      if (this?.page?.attributes?.meta_data?.author) {
        metaData.push({ hid: 'twitter:site', name: 'twitter:site', content: this.page.attributes.meta_data.author }, { hid: 'twitter:creator', name: 'twitter:creator', content: this.page.attributes.meta_data.author })
      }
      if (this?.page?.attributes?.meta_data?.description) {
        metaData.push({ hid: 'description', name: 'description', content: this.page.attributes.meta_data.description }, { hid: 'og:description', property: 'og:description', content: this.page.attributes.meta_data.description }, { hid: 'twitter:description', name: 'twitter:description', content: this.page.attributes.meta_data.description })
      }
      if (this?.page?.attributes?.meta_data?.image.data) {
        const imageUrl = this.outputImage(this.page.attributes.meta_data?.image?.data?.attributes, 'medium')
        metaData.push({ hid: 'og:image', name: 'image', property: 'og:image', content: imageUrl }, { hid: 'twitter:image', name: 'twitter:image', content: imageUrl })
      }
      return metaData
    },
  },
}
