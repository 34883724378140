
import { mapGetters } from 'vuex'
import NaviconCloseSVG from '../svg/btn/NaviconCloseSVG.vue'
import FooterLineSVG from '@/components/svg/footer/FooterLineSVG.vue'
import LogoSVG from '@/components/svg/LogoSVG.vue'
import NaviconOpenSVG from '@/components/svg/btn/NaviconOpenSVG.vue'
import InfoWrap from '@/components/wrappers/InfoWrap.vue'
import Socials from '@/components/parts/Socials.vue'
export default {
  components: {
    LogoSVG,
    BtnMain: () => import('@/components/parts/button/BtnMain.vue'),
    NaviconOpenSVG,
    FooterLineSVG,
    InfoWrap,
    Socials,
    NaviconCloseSVG,
  },
  data() {
    return {
      open: false,
      tl: null,
      menuTl: null,
    }
  },
  computed: {
    ...mapGetters({
      navbar: 'getNavbar',
    }),
  },
  watch: {
    open() {
      if (this.open) {
        this.$gsap.to('body', { overflow: 'hidden' })
        this.menuTl.play()
      } else {
        this.$gsap.to('body', { overflow: 'auto' })
        this.menuTl.reverse()
      }
    },
  },
  beforeDestroy() {
    this.$gsap.to('body', { overflow: 'auto' })
  },
  mounted() {
    this.$nuxt.$on('go', this.anim)

    this.tl = this.$gsap.timeline({
      paused: true,
      defaults: { ease: 'custom.1' },
    })
    this.tl.from(this.$refs.logo.$el, { delay: 1, opacity: 0, xPercent: -100, duration: 0.3 }, 0)
    this.tl.from(this.$refs.links.children, { delay: 1, opacity: 0, xPercent: 100, stagger: 0.1 }, 0)

    this.menuTl = this.$gsap.timeline({ paused: true })
    this.menuTl.set(this.$refs.menu, { display: 'flex' })
    this.menuTl.to(this.$refs.menu, { xPercent: -100, duration: 0.2, ease: 'custom.3' })
  },
  methods: {
    getComponent(index) {
      return index === this.navbar.menu.length - 1 ? 'BtnMain' : 'LinkPart'
    },
    getStyle(index) {
      return index !== this.navbar.menu.length - 1 ? 'duration-300 hover:text-ttk-green btn-font' : ''
    },
    anim() {
      this.tl.play()
    },
  },
}
