import { gql } from 'graphql-tag'

import { LinkFragment, ImageFragment, MultipleImagesFragment, LottieFragment } from './fragments/parts.js'
import { WordsFragment } from './fragments/words.js'
import { TeamMembersFragment } from './fragments/team-members.js'

export default gql`
  query Page($slug: String!) {
    page(slug: $slug) {
      data {
        id
        attributes {
          title
          slug
          meta_data {
            ... on ComponentStructureMetaData {
              title
              description
              author
              image {
                ... on UploadFileEntityResponse {
                  ...ImageFragment
                }
              }
            }
          }
          hero {
            ... on PageHeroDynamicZone {
              __typename
              ... on ComponentHerosHero {
                heading
                words {
                  ...WordsFragment
                }
                body
                logo {
                  ...LottieFragment
                }
              }
              # ... on ComponentHerosHeroBasic {
              #   lottie {
              #     ...LottieFragment
              #   }
              # }
              ... on ComponentHerosHeroImage {
                heading
                jumbotron
                image {
                  ...ImageFragment
                }
              }
              ... on ComponentHerosHeroStandard {
                heading
                body
              }
            }
          }
          sections {
            ... on PageSectionsDynamicZone {
              __typename
              ... on ComponentSectionsCopy {
                heading
                body
                align: align
                CTA {
                  ...LinkFragment
                }
                sectionID
              }
              ... on ComponentSectionsCopyImage {
                strapline
                body
                alignment: align
                variant
                image {
                  ...ImageFragment
                }
                sectionID
              }
              ... on ComponentSectionsCopyTwoImages {
                strapline
                body
                image1 {
                  ...ImageFragment
                }
                image2 {
                  ...ImageFragment
                }
                sectionID
              }
              ... on ComponentSectionsCopyInfo {
                strapline
                body
                info
                sectionID
              }
              ... on ComponentSectionsRichText {
                heading
                body
                sectionID
              }
              ... on ComponentSectionsDecoration1 {
                sectionID
              }
              ... on ComponentSectionsValues {
                heading
                first
                firstBody
                second
                secondBody
                third
                thirdBody
                sectionID
              }
              ... on ComponentSectionsFounders {
                heading
                members {
                  ...TeamMembersFragment
                }
                body
                cta {
                  ...LinkFragment
                }
                sectionID
              }
              ... on ComponentSectionsTeam {
                preheading
                heading
                members {
                  ...TeamMembersFragment
                }
                sectionID
              }
              ... on ComponentSectionsPedigree {
                heading
                body
                images {
                  ...MultipleImagesFragment
                }
                sectionID
              }
              ... on ComponentSectionsCareers {
                body
                sectionID
              }
              ... on ComponentSectionsCareersSmall {
                body
                count
                sectionID
              }
            }
            ... on ComponentSectionsNews {
              heading
              sectionID
            }
            ... on ComponentSectionsNewsSmall {
              heading
              CTA {
                ...LinkFragment
              }
              sectionID
            }
            ... on ComponentSectionsImage {
              image {
                ...ImageFragment
              }
              sectionID
            }
            ... on ComponentSectionsIcons {
              heading
              strapline
              body
              styleVariant: variant
              icons {
                heading
                icon {
                  ...LottieFragment
                  rive {
                    ...ImageFragment
                  }
                }
                
              }
              sectionID
            }
            ... on ComponentSectionsHeading {
              heading
              jumbotron
              sectionID
            }
            ... on ComponentSectionsJumboLink {
              strapline
              heading
              subheading
              image {
                ...ImageFragment
              }
              link {
                ...LinkFragment
              }
              sectionID
            }
          }
        }
      }
    }
  }
  ${ImageFragment}
  ${MultipleImagesFragment}
  ${TeamMembersFragment}
  ${WordsFragment}
  ${LinkFragment}
  ${LottieFragment}
`
