
import NavBar from '@/components/structure/NavBar.vue'
import Footer from '@/components/structure/Footer.vue'
import meta from '~/mixins/meta'
export default {
  components: {
    NavBar,
    Footer,
    Sections: () => import('~/components/structure/Sections.vue'),
  },
  mixins: [meta],
  props: {
    page: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      hero: false,
      sections: false,
    }
  },
  watch: {
    hero(val) {
      if (val && this.section) this.$nuxt.$emit('pageMounted')
    },
    sections(val) {
      if (val && this.hero) this.$nuxt.$emit('pageMounted')
      if (val && !this.page.attributes.hero.length) this.$nuxt.$emit('pageMounted')
    },
  },
}
