import { gql } from 'graphql-tag'
import { ImageFragment } from './parts'

export const TeamMembersFragment = gql`
  fragment TeamMembersFragment on TeamMemberRelationResponseCollection {
    data {
      id
      attributes {
        name
        role
        bio
        image {
          ...ImageFragment
        }
      }
    }
  }
  ${ImageFragment}
`
