import { gql } from 'graphql-tag'

export const WordsFragment = gql`
  fragment WordsFragment on WordRelationResponseCollection {
    data {
      id
      attributes {
        word
      }
    }
  }
`
